import Vue from 'vue';
import VueRouter from 'vue-router';
import MAuth from '@/middleware/auth';
import MPasswordCreated from '@/middleware/passwordCreated';
import MCreatePassword from '@/middleware/createPassword';
import MGuest from '@/middleware/guest';
import MNonAdminProjectPartner from '@/middleware/nonAdminProjectPartner';
// import MCheckoutValid from '@/middleware/checkoutValid';
import MLogin from '@/middleware/login';
import MActiveMembership from '@/middleware/activeMembership';
import MNonTrialMembership from '@/middleware/nonTrialMembership';
// import MNotActiveMembership from '@/middleware/notActiveMembership';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter);
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('index'),
    meta: {
      middlewares: [MAuth, MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('login'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('register'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/forget-pass',
    name: 'forget-pass',
    component: loadView('forget-pass'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/user/activation/:token',
    name: 'user-activation',
    component: loadView('user/activation/_token'),
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: loadView('password/reset/_token'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/user/create-password',
    name: 'create-password',
    component: loadView('user/create-password'),
    meta: {
      middlewares: [MAuth, MPasswordCreated],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: loadView('profile/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: loadView('change-password/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/referral-code',
    name: 'referral-code',
    component: loadView('referral-code/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/input-phone',
    name: 'input-phone',
    component: loadView('input-phone/index'),
    meta: {
      middlewares: [MLogin],
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: loadView('otp/index'),
    meta: {
      middlewares: [MLogin],
    },
  },
  // {
  //   path: '/privacy-policy',
  //   name: 'privacy-policy',
  //   component: loadView('privacy-policy'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/term-of-service',
  //   name: 'term-of-service',
  //   component: loadView('term-of-service'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/product',
    name: 'product',
    component: loadView('product/index'),
    meta: {
      middlewares: [MAuth, MCreatePassword, MNonAdminProjectPartner, MNonTrialMembership],
    },
  },
  {
    path: '/coin',
    name: 'coin',
    component: loadView('product/index'),
    meta: {
      middlewares: [
        MAuth,
        MCreatePassword,
        MNonAdminProjectPartner,
        MActiveMembership,
        MNonTrialMembership,
      ],
    },
  },
  {
    path: '/listing-availability',
    name: 'listing-availability',
    component: loadView('product/index'),
    meta: {
      middlewares: [
        MAuth,
        MCreatePassword,
        MNonAdminProjectPartner,
        MActiveMembership,
        MNonTrialMembership,
      ],
    },
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: loadView('product/index'),
    meta: {
      middlewares: [MAuth, MCreatePassword, MNonAdminProjectPartner, MActiveMembership],
    },
  },
  {
    path: '/renewal',
    name: 'renewal',
    component: loadView('product/index'),
    meta: {
      middlewares: [
        MAuth,
        MCreatePassword,
        MNonAdminProjectPartner,
        MActiveMembership,
        MNonTrialMembership,
      ],
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: loadView('checkout/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/how-to-pay/:uuid',
    name: 'how-to-pay',
    component: loadView('how-to-pay/_uuid/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/order',
    name: 'order',
    component: loadView('order/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/invoice/:uuid',
    name: 'invoice',
    component: loadView('order/invoice'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/listing/add',
    name: 'add-listing',
    component: loadView('listing/add/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MActiveMembership],
    },
  },
  {
    path: '/listing/edit/:uuid',
    name: 'edit-listing',
    component: loadView('listing/edit/_uuid/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/coin-purchase',
    name: 'coin-purchase',
    component: loadView('coin-purchase/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/coin-usage',
    name: 'coin-usage',
    component: loadView('coin-usage/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/agent-offerings',
    name: 'agent-offerings',
    component: loadView('agent-offerings'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/leads',
    name: 'leads',
    component: loadView('leads/index'),
    meta: {
      middlewares: [MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/banner/detail/:uuid',
    name: 'banner-detail',
    component: loadView('banner/detail/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  {
    path: '/bulk-repost',
    name: 'bulk-repost',
    component: loadView('bulk-repost/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MActiveMembership],
    },
  },
  { path: '*', component: loadView('404') },
];

export default () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        console.log('ADA HASH-NYA BUAT ANCHOR: ', to.hash);
        return {
          selector: to.hash,
        };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtm, {
      id: 'GTM-55FL7CX3',
      vueRouter: router,
      debug: false,
    });
  }

  return router;
};
